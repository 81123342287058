import { ActionContext, Module } from "vuex";

export interface SurveyDatasourceState {
    surveyDatasource: Record<string, any>; 
}

const state: SurveyDatasourceState = {
    surveyDatasource: {},
};

const mutations = {
    setSurveyDatasource(state: SurveyDatasourceState, payload: Record<string, any>) {
        state.surveyDatasource = payload;
    },
};
const actions = {
    updateSurveyDatasource(
        { commit }: ActionContext<SurveyDatasourceState, any>, 
        payload: Record<string, any>
    ) {
        commit("setSurveyDatasource", payload);
    },
};

const getters = {
    getSurveyDatasource(state: SurveyDatasourceState): Record<string, any> {
        return state.surveyDatasource;
    },
};

// Export the module with correct typings
export const surveyDatasource: Module<SurveyDatasourceState, any> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
