import { createStore } from "vuex";
import AuthModule from "./module/auth";
import {surveyDatasource}  from "./module/surveyDatasource";


const store = createStore({
  modules: {
    auth: AuthModule,
    surveyDatasource
  },
});

export default store;
